.file {
    font-size: 2.4rem;
}

.file-header {
    font-size: 1.9rem;
    color: rgb(5, 5, 48);
    text-transform: capitalize;
}

.file-text-sub,
.file-text-sub- {
    color: rgb(5, 5, 48);
    font-size: 1rem;
}

.file-text-sub- {
    opacity: 0.8;
}

.file-upload {
    margin-top: 2%;
}

.master-text {
    font-size: 2vh;
    font-weight: bold;
}

.master-screen {
    display: flex;
    justify-content: flex-start;
    margin-top: 4vh;
}

.master-autocompleteExcel {
    margin-left: 1vw;
}

.file-text {
    display: flex;
    justify-content: center;
    margin-top: 12vh;
    font-weight: bold;
    opacity: 0.7;
}

.file-text-button {
    margin-left: 2vw;
    margin-top: 1vh;
}

.file-text-button:hover {
    cursor: pointer;
}


.align-excel {
    margin-top: 2vh;
    /* margin-left: 5vw; */
    width: 94vw;
    display: flex;

}

.align-excel-head {
    margin-top: 2%;
    width: 83vw;
    display: flex;
    justify-content: space-evenly;
    margin-left: -4%;
}

.table-body {
    margin-top: 1vh;
}

.submit {
    display: flex;
    justify-content: center;
    margin-left: 38%;
    margin-top: 3vh;
}

.model-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: -2vh;
}

.excel-param {
    margin-left: 5%;
    font-size: 2rem;
}

.close {
    margin-right: 5%;
    font-size: 2rem;
}

.no-divider>td {
    border-bottom: none;
}

.no-divider>th {
    font-size: 1rem;
    font-weight: bold;

}