.no-scroll {
    overflow: hidden;
}
.main-log {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
}
.background-image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("bg-img/Eira-BG.png");
    
    background-size: cover;
    filter: blur(5px);
    z-index: -1;
    overflow: hidden;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Adjust the color and opacity as needed */
    z-index: -1;
}

.eira-logo {
    width: max-content;
    height: max-content;
    margin: 20px auto 0 auto;
    overflow: hidden;
}
.mail-pass {
    /* border:1px solid green; */
    width: 45ch;
    margin: 10px 0;
    overflow: hidden;
}
.head-login {
    display: flex;
    justify-content: center;
    margin-left: 6%;
    margin-top: 5%;
    overflow: hidden;
}
a {
    text-decoration: none;
    overflow: hidden;
}
.img-login {
    width: 50%,
}
.text {
    margin-top: 38%;
    font-size: 2.5rem;
    margin-left: -101%;
}
.text-field {
    margin-left: 7%;
}
.login-text {
    margin-left: 7%;
    margin-top: -4%;
}
.forget-password {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
}
.link-forget {
    margin-right: 10%;
    margin-top: 2%;
    font-size: 1.2rem;
}
.request {
    display: flex;
    justify-content: center;
    margin-top: 8%;
    margin-left: -10%;
    font-size: 1.2rem;
}
.req-acc {
    margin-left: 2%;
}
.hand {
    margin-left: -2%;
    margin-top: -1%;
}