.alert-details {
    display: flex;
    margin-left: 2%;
    margin-top: 2%;
}
.reset-styles {
    padding: 0;
    margin-bottom: 10px;
  }
/* .ac-power {
    display: flex;
} */

.Paperss{
    /* display: flex; */
    /* margin-left: 2%; */
}

