/* styles.css */
body {
  background-color: aliceblue;
}
.h3 {
  background-color: rgb(18, 127, 216);
}

.highcharts-tooltip {
  pointer-events: auto !important;
}
