/* .MuiAppBar-positionFixed,
.mui-fixed,
.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    color: cornflowerblue !important;
} */



.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-sizeSmall,
.MuiInputBase-adornedStart,
.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
    padding-left: 0% !important;
}

.MuiDrawer-paperAnchorLeft,
.MuiDrawer-paperAnchorDockedLeft {
    background-color:#abc4c4!important;
    color:"white"
}

/* .css-19z1ozs-MuiPaper-root-MuiAppBar-root,
.MuiToolbar-root,
.MuiToolbar-gutters,
.MuiToolbar-regular,
.css-hyum1k-MuiToolbar-root {
    background-color: whitesmoke !important;
} */

.header-right {
    margin-top: 1vh;
    display: flex;
    justify-content: space-evenly;
    /* color: white; */
    margin-right: 2vh;
}

.header-drawer {
    display: flex;
    justify-content: space-between;
    margin-left: -5vw;
}

.header-left {
    padding-bottom: 2vh;
}

.image {
    /* float: right; */
    width: 70vw;
}

.eira {
    margin-left: -4vw;
    font-size: 2.5rem;
    margin-top: 1%;
}



.searchbar {
    width: '23vw';
    /* background-color: 'whitesmoke'; */
    margin-top: '1vh';
    margin-left: '1vw';
    border-radius: '80%';
}

.header-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* color: white; */
    margin-right: -1.5vw;
}

/* .eir-image{
    margin-left: -70px;
} */
.image {
    width: 2vw;
    height: 4.5vh;
    margin-left: -9vw;
    margin-top: 0.8vh;

}
/*------------------------------------------------- Media Quires for Image------------------------------*/
@media screen and (min-width: 1024px) {
    .image{
        width:2.5vw;
        height: 7.5vh;
        margin-left: -14vw;
    }
}
@media screen and (min-width: 1440px) {
    .image{
        width:2.5vw;
        height: 4.5vh;
        margin-left: -11vw;
    }
}
@media screen and (min-width: 1440px) {
    .image{
        width:2vw;
        height: 4.5vh;
        margin-left: -9vw;
    }
}

