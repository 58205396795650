body {
  user-select: none;
}

.root {
  display: flex;
  width: 120rem;
  height: 590px;
}

.disable-cursor {
  cursor: default !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.table {
  background-color: white;
  width: 93%;
  height: 100%;
}

.toolbar {
  background-color: white;
  display: flex;
  align-content: center;
  width: 93%;
  height: 50px;
  margin-bottom: 20px;
}

.searchbox {
  display: flex;
  width: auto;
  height: 2px;
}

.alarmButton {
  margin-left: 69rem;
}

@media (max-width: 1600px) and (min-width: 1100px) {
  .root {
    width: 93rem;
  }
  .alarmButton {
    margin-left: 45rem;
  }
}

@media (max-width: 1099px) and (min-width: 900px) {
  .root {
    width: 60rem;
  }
  .alarmButton {
    margin-left: 15rem;
  }
}

@media (max-width: 899px) and (min-width: 500px) {
  .root {
    width: 58rem;
  }
  .alarmButton {
    margin-left: 13rem;
  }
}
