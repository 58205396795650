.analytics {
    display: flex;
    justify-content: space-between;
}
/* .monitoring-points {
    margin-left: 2%;
    margin-top: 1%;
} */
/* .monitor-head {
    margin: 2%;
} */
.monitor-text {
    /* margin-left: 2%; */
    margin-top: 20px;
}
.monitoring-graph {
    display: flex;
    margin-left: 2%;
}
.icons {
    display: flex;
    justify-content: space-evenly;
}
.graph {
    margin-top: 1%;
    margin-left: 5%;
    height: 100%;
}
.card-allign {
    margin-top: 4%;
    margin-bottom: 3%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.card-icon {
    margin-right: 2%;
}
.autocomplete {
    font-family: sans-serif;
}
.custom-select {
    width: 250px;
   
}
.custom-option {
    white-space: normal;
  
}
/*----------------------------------- Analyst Card css ----------------------------*/
.card-analyst {
    margin-top: 1%;
}
/* Apply Flexbox to the container */
.apply-changes {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* 
@media (min-width: 1024px) {
    .apply-changes {
      margin-right: 3%;
    }
} */
/* @media (min-width: 1440px) {
    .apply-changes {
        margin-top: 30px;
    }
} */