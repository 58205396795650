


.equipment {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.box-typo {
    margin-top: -150px !important;
    /* margin-top: -150px; */
}

.total-custom{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}

.total-equip{
    white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}
.mouse-over {
    position: relative;
  }
  
  .mouse-over:hover::before {
     /* Replace with your actual sorting icon */
    visibility: hidden;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding-left: 4px; /* Adjust the padding as needed */
  }
  
  .mouse-over:hover {
    cursor: pointer; /* Change cursor to a pointer on hover */
  }
  .alerms{
    display:flex;
    justify-content:center;
    margin-top: 15%;

  }
