/* Set a container to cover the full viewport */
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/solar5.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  filter: blur(3px);
  z-index: -1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(247, 235, 67, 0.5); Adjust opacity as needed */
  z-index: -1;
}

.signup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  /* max-width: 320px;
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem; */



  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;


  /* border-radius: 30px;
  background: #bbbbbb;
  box-shadow: 5px 15px 30px #EF9B10,
    -5px -5px 5px  #0187CA; */


  /* background-color: rgba(255, 255, 255, 0.1); 
  backdrop-filter: blur(10px); 
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4);  */


  padding: 2%;
  max-width: 30%;
  color: black;
}



.signup-container * {
  font-weight: bold;
}

.signup-container input:focus {
  outline: none;
}

.signup-container label,
.signup-container input[type="text"],
.signup-container input[type="email"],
.signup-container input[type="password"],
.signup-container textarea {

  background-color: #F2F2F2;
  color: rgb(0, 0, 0);
  /* border-radius: 10px; */
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.link-signup {
  font-weight: bold;
  color: rgb(21, 98, 212);
  text-decoration: underline;
}

.link-signup:hover {
  color: rgb(0, 140, 255);
}


@media (max-width: 768px) {
  .signup-container {
    min-width: 60%;
    min-height: 60%;

  }
}

@media (max-width: 1024px) {
  .signup-container {
    max-width: 60%;
    /* Adjust container width for 1024px */
  }
}